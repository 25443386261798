<template>
  <el-dialog
    :title="$t('role.bindDepart')"
    v-model="isShow"
    width="900px"
    :before-close="handleClose"
  >
    <el-container>
      <div style="display: flex; justify-content: space-between; width: 100%">
        <div>
          <el-input
            v-model="leftFilter"
            size="mini"
            :placeholder="$t('role.filter')"
            style="margin-bottom: 5px"
          ></el-input>
          <el-table
            ref="userTable"
            :data="allUser.filter(filterLeft)"
            @selection-change="onLeftSelectionChange"
            size="mini"
            border
            height="255"
            highlight-current-row
            @current-change="handleCurrentChange"
          >
            <el-table-column
              prop="account"
              align="center"
              show-overflow-tooltip
              :label="$t('role.account')"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="name"
              align="center"
              show-overflow-tooltip
              :label="$t('role.userName')"
              width="150"
            ></el-table-column>
          </el-table>
        </div>
        <div style="flex: 1; margin-left: 20px">
          <div style="margin-bottom: 14px">已绑定部门</div>
          <div
            style="border: 1px #c0c4cc solid; height: 252px; overflow-y: scroll"
          >
            <el-tree
              ref="tree"
              :data="treeData"
              show-checkbox
              node-key="id"
              :props="defaultProps"
              accordion
              :render-content="renderContent"
            >
            </el-tree>
          </div>
        </div>
      </div>
    </el-container>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doBindDepart" size="mini"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import accountServer from "../source/account";
export default {
  props: ["isShowDialog", "roleId"],
  emits: ["onDialogClose"],
  data() {
    return {
      leftFilter: null,
      allUser: [],
      currentRow: null,
      treeData: [
        {
          id: 1,
          label: "跨境",
          children: [],
        },
        {
          id: 2,
          label: "运力",
          children: [],
        },
        {
          id: 3,
          label: "海外",
          children: [],
        },
        {
          id: 4,
          label: "空运",
          children: [],
        },
        {
          id: 5,
          label: "服装",
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      departs: [],
    };
  },
  computed: {
    isShow() {
      return this.isShowDialog;
    },
  },
  created() {
    this.queryDepartment();
  },
  methods: {
    renderContent(h, { node, data }) {
      return node.isLeaf ? (
        <span>
          <span>
            {data.companyName} - {node.label}
          </span>
        </span>
      ) : (
        <span>
          <span>{node.label}</span>
        </span>
      );
    },

    handleCurrentChange(val) {
      this.currentRow = val;
      this.queryUserAuth(val);
    },

    queryBindedUser() {
      accountServer.queryBindedUser({ roleId: this.roleId }).then((res) => {
        this.allUser = res.data ? res.data : [];
        if (this.allUser.length > 0) {
          this.queryUserAuth(this.allUser[0]);
          this.$nextTick(() => {
            this.$refs.userTable.setCurrentRow(this.allUser[0]);
          });
        }
      });
    },

    queryUserAuth(user) {
      accountServer.queryUserDateAuth({ userId: "" + user.id }).then((res) => {
        let ids = [];
        res.data.areas.forEach((area) => {
          ids = ids.concat(area.ids.map((i) => `${area.areaCode}_${i}`));
        });
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(ids);
        });
      });
    },

    queryDepartment() {
      accountServer.queryDepartment().then((res) => {
        this.departs = res.data;
        this.treeData.forEach((biz) => {
          biz.children = res.data.map((depart) => ({
            id: biz.id + "_" + depart.departmentId,
            label: depart.departmentName,
            companyName: depart.companyName,
          }));
        });
        this.queryBindedUser();
      });
    },

    handleClose() {
      this.$emit("onDialogClose");
    },

    filterLeft(item) {
      if (!item.orgName) item.orgName = "";
      if (!item.account) item.account = "";
      if (!item.name) item.name = "";
      if (this.leftFilter == null || this.leftFilter.trim() == "") {
        return !item.binded;
      } else {
        return (
          !item.binded &&
          (item.account.indexOf(this.leftFilter) != -1 ||
            item.name.indexOf(this.leftFilter) != -1 ||
            item.orgName.indexOf(this.leftFilter) != -1)
        );
      }
    },

    doBindDepart() {
      console.log(this.$refs.tree.getCheckedKeys());

      let selection = this.$refs.tree.getCheckedKeys();
      let param = {
        areas: [
          { areaCode: 1, ids: [] },
          { areaCode: 2, ids: [] },
          { areaCode: 3, ids: [] },
          { areaCode: 4, ids: [] },
          { areaCode: 5, ids: [] },
        ],
        userId: this.currentRow.id,
      };

      selection.forEach((key) => {
        let kv = `${key}`.split("_");
        if (kv[1]) {
          param.areas[Number(kv[0]) - 1].ids.push(kv[1]);
        }
      });

      accountServer
        .bindUserDateAuth({
          ...param,
          areas: param.areas.filter((i) => i.ids.length > 0),
        })
        .then(() => {
          this.$message.success("部门绑定成功");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.transfer {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  justify-content: space-around;
}

/deep/ .el-transfer__buttons {
  width: 0;
  margin-right: 60px;
  .el-transfer__button {
    margin-left: 0;
  }
}
</style>

<template>
  <el-dialog
    :title="$t('role.bindUser')"
    v-model="isShow"
    width="1000px"
    :before-close="handleClose"
  >
    <el-container>
      <div style="display:flex;justify-content:space-between;width:100%;">
        <div style="flex:1">
          <el-input
            v-model="leftFilter"
            size="mini"
            :placeholder="$t('role.filter')"
            style="margin-bottom: 5px"
          ></el-input>
          <el-table
            :data="allUser.filter(filterLeft)"
            @selection-change="onLeftSelectionChange"
            height="400"
          >
            <el-table-column type="selection" width="50px"></el-table-column>
            <el-table-column
              prop="account"
              align="center"
              show-overflow-tooltip
              :label="$t('role.account')"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="name"
              align="center"
              show-overflow-tooltip
              :label="$t('role.userName')"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="orgName"
              align="center"
              show-overflow-tooltip
              :label="$t('role.organization')"
            ></el-table-column>
          </el-table>
        </div>
        <div class="transfer">
          <el-button
            icon="el-icon-arrow-left"
            circle
            size="mini"
            @click="setBind(false)"
          />
          <el-button
            icon="el-icon-arrow-right"
            circle
            size="mini"
            @click="setBind(true)"
          />
        </div>
        <div style="flex: 1"><el-input
            v-model="rightFilter"
            size="mini"
            :placeholder="$t('role.filter')"
            style="margin-bottom:5px;"
          ></el-input>
          <el-table
            :data="allUser.filter(filterRight)"
            @selection-change="onRightSelectionChange"
            height="400"
          >
            <el-table-column
              type="selection"
              width="50px"
            ></el-table-column>
            <el-table-column
              prop="account"
              align="center"
              show-overflow-tooltip
              :label="$t('role.account')"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="name"
              align="center"
              show-overflow-tooltip
              :label="$t('role.userName')"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="orgName"
              align="center"
              show-overflow-tooltip
              :label="$t('role.organization')"
            ></el-table-column>
          </el-table></div>
        <!-- <div style="flex: 1">
          
        </div>

        <div class="transfer">
          <el-button
            icon="el-icon-arrow-left"
            circle
            size="mini"
            @click="setBind(false)"
          />
          <el-button
            icon="el-icon-arrow-right"
            circle
            size="mini"
            @click="setBind(true)"
          />
        </div>

        <div style="flex: 1">
          
        </div> -->
      </div>
    </el-container>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doBindUser" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import accountServer from "../source/account";
export default {
  props: ["isShowDialog", "roleId"],
  emits: ["onDialogClose"],
  data() {
    return {
      leftFilter: null,
      allUser: [],
      rightFilter: null,
      leftSelection: [],
      rightSelection: [],
    };
  },
  computed: {
    isShow() {
      return this.isShowDialog;
    },
  },
  created() {
    this.queryAllUser();
  },
  methods: {
    queryAllUser() {
      accountServer.queryUser({ current: 1, size: 10000 }).then((res) => {
        this.allUser = res.data ? res.data.records : [];
        this.queryBindedUser();
      });
    },
    queryBindedUser() {
      accountServer.queryBindedUser({ roleId: this.roleId }).then((res) => {
        res.data.forEach((i) => {
          this.allUser.some((user) => {
            if (i.id == '' +  user.id) {
              user.binded = true;
              return true;
            }
          });
        });
      });
    },
    handleClose() {
      this.$emit("onDialogClose");
    },
    onLeftSelectionChange(val) {
      this.leftSelection = val;
    },
    onRightSelectionChange(val) {
      this.rightSelection = val;
    },
    filterLeft(item) {
      if (!item.orgName) item.orgName = "";
      if (!item.account) item.account = "";
      if (!item.name) item.name = "";
      if (this.leftFilter == null || this.leftFilter.trim() == "") {
        return !item.binded;
      } else {
        return (
          !item.binded &&
          (item.account.indexOf(this.leftFilter) != -1 ||
            item.name.indexOf(this.leftFilter) != -1 ||
            item.orgName.indexOf(this.leftFilter) != -1)
        );
      }
    },
    filterRight(item) {
      if (!item.orgName) item.orgName = "";
      if (!item.account) item.account = "";
      if (!item.name) item.name = "";
      if (this.rightFilter == null || this.rightFilter.trim() == "") {
        return item.binded;
      } else {
        return (
          item.binded &&
          (item.account.indexOf(this.rightFilter) != -1 ||
            item.name.indexOf(this.rightFilter) != -1 ||
            item.orgName.indexOf(this.rightFilter) != -1)
        );
      }
    },

    setBind(bind) {
      if (bind) {
        //选择
        this.leftSelection.forEach((item) => {
          item.binded = true;
        });
        this.leftSelection = [];
      } else {
        //取消选择
        this.rightSelection.forEach((item) => {
          item.binded = false;
        });
        this.rightSelection = [];
      }
    },

    doBindUser() {
      let ids = [];
      this.allUser.forEach((user) => {
        if (user.binded) ids.push(user.id);
      });

      accountServer
        .distributeRole({ roleId: this.roleId, ids: ids })
        .then(() => {
          this.$message.success(this.$t("role.bindSuccess"));
          this.handleClose();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.transfer {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  justify-content: space-around;
}
</style>

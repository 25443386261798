<template>
  <div class="container">
    <el-container>
      <el-header >
        <el-row type="flex" :gutter="10" style="width: 100vw">
          <el-col :span="3">
            <el-input
              v-model="filterOption.name"
              :placeholder="$t('role.name')"
              size="mini"
            ></el-input>
          </el-col>

          <el-col :span="3">
            <el-select
              filterable
              v-model="filterOption.tenant"
              :placeholder="$t('role.system')"
              size="mini"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in systemList"
                :key="index"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="4" :offset="14">
            <div style="float: right">
              <el-button type="primary" size="mini" @click="doSearch">{{
                $t("system.search")
              }}</el-button>
              <el-button type="warning" size="mini" @click="doReset">{{
                $t("system.reset")
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="tableHeader">
          <!-- <el-button size="mini">{{ $t("system.import") }}</el-button> -->
          <el-button size="mini" @click="showCreateDialog">{{
            $t("system.create")
          }}</el-button>
          <!-- <el-button size="mini">{{ $t("system.delete") }}</el-button> -->
        </div>
        <el-table
          :data="tableData"
          ref="accountTable"
          border
          :height="tHeight"
          v-loading="isTableLoading"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('system.index')"
            width="60"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="name"
            :label="$t('role.name')"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="tenantType"
            :label="$t('role.system')"
            align="center"
            show-overflow-tooltip
          ><template #default="scope">
            {{systemList.find(item => item.id == scope.row.tenantType).name}}
          </template>
          </el-table-column>
          <el-table-column
            :label="$t('role.status')"
            align="center"
            width="120"
            show-overflow-tooltip
            ><template #default="scope">
              <span>
                <el-tag v-if="scope.row.locked == 0" type="success">{{
                  $t("system.enable")
                }}</el-tag>
                <el-tag v-else type="danger">{{ $t("system.disable") }}</el-tag>
              </span>
            </template></el-table-column
          >
          <el-table-column
            :label="$t('system.operation')"
            fixed="right"
            align="center"
            width="250"
          >
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="edit(scope.row)"
                >{{ $t("system.edit") }}</el-button
              >
              <el-button
                type="text"
                style="color: #e6a23c"
                size="mini"
                icon="el-icon-user"
                @click="bind(scope.row)"
                >{{ $t("role.bindUser") }}</el-button
              >
              <el-button
              v-if="scope.row.name == '业财报表管理'"
                type="text"
                style="color: #e6a23c"
                size="mini"
                icon="el-icon-key"
                @click="bindDepart(scope.row)"
                >{{ $t("role.bindDepart") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin-top: 10px">
          <el-pagination
            @size-change="onTableSizeChange"
            @current-change="onTablePageChange"
            :current-page="filterOption.pageNo"
            :page-sizes="[10, 50, 100, 200]"
            layout="total,sizes,prev, pager, next"
            :total="filterOption.total"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>

    <create-Role-dialog
      v-if="isShowCreateDialog"
      :isShowDialog="isShowCreateDialog"
      @onDialogClose="closeCreateDialog"
    />
    <update-Role-dialog
      v-if="isShowUpdateDialog"
      :isShowDialog="isShowUpdateDialog"
      :roleId="currentRoleId"
      @onDialogClose="closeUpdateDialog"
    />
    <bind-user
      :isShowDialog="isShowBindDialog"
      v-if="isShowBindDialog"
      :roleId="currentRoleId"
      @onDialogClose="closeBindDialog"
    />
    <bind-depart
      :isShowDialog="isShowBindDepartDialog"
      v-if="isShowBindDepartDialog"
      :roleId="currentRoleId"
      @onDialogClose="closeBindDialog"
    />
  </div>
</template>

<script>
import accountServer from "./source/account.js";
import mixin from "@/utils/mixin.js";
import CreateRoleDialog from "./dialogs/createRole";
import UpdateRoleDialog from "./dialogs/updateRole";
import BindUser from "./dialogs/bindUser";
import BindDepart from "./dialogs/bindDepart";
export default {
  name: "role",
  components: {
    CreateRoleDialog,
    UpdateRoleDialog,
    BindUser,
    BindDepart
  },
  mixins: [mixin],
  data() {
    return {
      isTableLoading: false,
      isShowCreateDialog: false,
      isShowUpdateDialog: false,
      isShowBindDialog: false,
      isShowBindDepartDialog:false,
      filterOption: {
        name: null,
        tenant: 1,

        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      systemList: [
        { id: 1, name: "道骊Admin" },
        { id: 2, name: "道骊OMS" },
        {id: 3, name: "道骊CBS" },
        {id: 4, name: "道骊BMS" },
        {id: 5, name: "道骊CBS-Client" },
        {id: 8, name: "货站OMS" },
      ],

      tableData: [],
      currentRoleId: null,
    };
  },
  computed: {
    tHeight() {
      return this.tableHeight - 135;
    },
  },
  mounted() {},
  methods: {
    doSearch() {
      accountServer
        .queryRole({
          ...this.filterOption,
          current: this.filterOption.pageNo,
          size: this.filterOption.pageSize,
        })
        .then((res) => {
          this.tableData = res.data ? res.data.records : [];
          this.filterOption.total = res.data.total;
        });
    },
    edit(row) {
      this.currentRoleId = row.id;
      this.isShowUpdateDialog = true;
    },
    bind(row) {
      this.currentRoleId = row.id;
      this.isShowBindDialog = true;
    },
bindDepart(row) {
      this.currentRoleId = row.id;
      this.isShowBindDepartDialog = true;
    },


    onTableSizeChange(size) {
      this.filterOption = {...this.filterOption,  pageSize: size,pageNo:1}
      this.doSearch()
     
    },
    onTablePageChange(page) {
      this.filterOption = {...this.filterOption,  pageNo: page,}
      this.doSearch()
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
    closeUpdateDialog() {
      this.isShowUpdateDialog = false;
    },
    closeBindDialog() {
      this.isShowBindDialog = false;
      this.isShowBindDepartDialog = false;
    },
    doReset() {
      this.filterOption = {
        name: null,
        tenant: 1,
        pageNo: 1,
        pageSize: 10,
        total: 0,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>
